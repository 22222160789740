import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {useStaticQuery, graphql} from "gatsby"
import Layout from "../components/layout";
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Img from 'gatsby-image'
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },

    root: {
        padding: theme.spacing(5, 0, 1, 1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
        }
    },
    title: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2vw',
        fontWeight: 'bold',
        color: 'seashell',
        textShadow: '1px 1px 150px #080705',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vh',
            margin: '2rem 0 0 0 ',
        }
    },

    media: {
        height: 'fit-content',
        width:'100%',
        [theme.breakpoints.down('xs')]: {
            height: 250,
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    paragraphTitle: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2rem',
        fontWeight: 'bold',
        color: '#f2f2f2',
        textAlign: 'center',
        marginTop: '2rem',
        marginBottom:'2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vw',
            lineHeight: '3rem',
            marginTop: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '4vw',
            lineHeight: '0',
            marginTop: '0rem',
        },
    },

    link:{
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        // textDecoration: 'none',
        color: '#dce1e3',
        '&:hover': {
            color: 'goldenrod',
            '&::after': {
                content: '27F6',
                display: 'block',
                marginTop: 'auto',
                fontSize: '1.2rem',
            }
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '3vw',
        },
    },
    paragraph: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.5rem',
        padding: '1rem',
        // marginTop: '',
        lineHeight: '2.5rem',
        color: '#f2f2f2',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5vw',
            marginTop: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5vh',
            lineHeight: '1.2',
        },
    },

    otherContents: {
        color: '#f2f2f2',
        fontSize: '1.8rem',
        marginTop: '3rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8vh',
            lineHeight: '2rem',
        },
    },

    icon: {
        color: 'white',
        fontSize: '1.7rem',
        margin: theme.spacing(3),
        border: '1px solid',
        '&:hover': {
            color: 'goldenrod',
            background: 'rgba(0,0,0,0) 0%',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    contents:{
        width:'100%',
        margin:'1rem',
        border:'1px solid white',
        boxShadow: '0 10px 25px 0 rgb(133 191 186 / 50%)',

        [theme.breakpoints.down('sm')]: {
            margin: '0 0 1rem 0'
        },
    }
}));

export default function Discography() {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query{
            placeholderImage: file(relativePath: {eq: "jonetsu.png"}) {
                childImageSharp {
                    fluid(quality: 65, maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            placeholderImage2: file(relativePath: {eq: "kazaguruma.png"}) {
                childImageSharp {
                    fluid(quality: 65, maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            placeholderImage3: file(relativePath: {eq: "MagicHour.png"}) {
                childImageSharp {
                    fluid(quality: 65, maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            placeholderImage4: file(relativePath: {eq: "memories1.png"}) {
                childImageSharp {
                    fluid(quality: 65, maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            placeholderImage5: file(relativePath: {eq: "maple_story.png"}) {
                childImageSharp {
                    fluid(quality: 65, maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            
        }`
    );

    return (
        <Layout>
            <Container maxWidth="lg" component="main" className={classes.root}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    Discography
                </Typography>

                <Grid container className={classes.contents} spacing={5}>

                    <Grid item xs={12} md={6}>
                        <Img
                            alt={data.placeholderImage2.name}
                            fluid={data.placeholderImage2.childImageSharp.fluid}
                            className={classes.media}
                            objectPosition="10% 10%"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            2023/6/28
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            “KAZAGURUMA” - single
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">
                            Compose, Arrange, Guitar / Aoi MichellE<br/>
                            Producer / Nao Tsuzuki
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">

                            Digital Release<br/>
                            <a href="https://linkco.re/mq3HsP2f?lang=ja"
                               className={classes.link}>
                                <span>https://linkco.re/mq3HsP2f?lang=ja</span>
                            </a>
                            <br/>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.contents} spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Img
                            alt={data.placeholderImage.name}
                            fluid={data.placeholderImage.childImageSharp.fluid}
                            className={classes.media}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            2023/7/19
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            “情熱” - single
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">
                            Compose, Arrange, Guitar / Aoi MichellE<br/>
                            Producer / Nao Tsuzuki
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">

                            Digital Release<br/>
                            <a href="https://linkco.re/B2vxGBtm?lang=ja"
                               className={classes.link}>
                                <span>https://linkco.re/B2vxGBtm?lang=ja</span>
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.contents} spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Img
                            alt={data.placeholderImage3.name}
                            fluid={data.placeholderImage3.childImageSharp.fluid}
                            className={classes.media}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            2023/8/22
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            “Magic Hour” - single
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">
                            Compose, Arrange, Guitar / Aoi MichellE<br/>
                            Producer / Nao Tsuzuki
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">

                            Digital Release<br/>
                            <a href="https://linkco.re/TMUaSxSS?lang=ja"
                               className={classes.link}>
                                <span>https://linkco.re/TMUaSxSS?lang=ja</span>
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.contents} spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Img
                            alt={data.placeholderImage3.name}
                            fluid={data.placeholderImage5.childImageSharp.fluid}
                            className={classes.media}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            2023/10/24
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            ” Maple story “ - single
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">
                            Compose, Arrange, Guitar / Aoi MichellE<br/>
                            Producer / Nao Tsuzuki
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">

                            Digital Release<br/>
                            <a href="https://linkco.re/z2QytDVv?lang=ja"
                               className={classes.link}>
                                <span>https://linkco.re/z2QytDVv?lang=ja</span>
                            </a>
                        </Typography>
                    </Grid>
                </Grid>

            </Container>
        </Layout>
    );
}
